<template>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialog" width="500">
      <v-card >
        <BarTitle :title="title" @close="close()"></BarTitle>
        <v-card-text>
            <p class="text-center" style="font-size: 18px;">{{message}}</p>
            <p class="text-center" style="font-size: 16px;">{{message1}}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-row>
                <v-col class="text-right">
                    <v-btn x-large color="primary" @click="close()" >ok</v-btn>
                    <!-- <v-btn :loading="loading" :disabled="loading" x-large color="error" @click="$emit('confirm')" >Excluir</v-btn> -->
                </v-col>
            </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>

</template>

<script>
import BarTitle from '@/pages/Components/BarTitle.vue'
export default {
    props: {
        loading:{type:Boolean},
        value:{},
        title:{
            type:String,
            default:'apagar registro'
        },
        message: {
            type: String,
            default: 'Deseja excluir ?'
        },
        message1: {
            type: String,
            default: 'Deseja excluir ?'
        }
    },
    components:{
         BarTitle,
    },

    data: () => ({
        dialog : false,
    }),

    watch:{
        value(val){
            this.dialog = val
        },
        dialog(val){
            this.$emit('input',val)
        }
    },
    methods:{
        close(){
            this.$emit('close');
            this.dialog = false

        }
    }
};
</script>
